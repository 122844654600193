"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var hasSetupJSAPIHook = false;
exports.default = (function (_, socket) {
    socket.fireEvent({
        method: "MiniAppLog.enable" /* enable */,
        params: {},
        ext: {
            origin: 'applog',
        },
    });
    if (!hasSetupJSAPIHook) {
        hasSetupJSAPIHook = true;
        setupJSAPIHook(socket);
    }
});
function setupJSAPIHook(socket) {
    if (window.dd && lodash_1.get(window.dd, 'env.platform') !== 'notInDingTalk') {
        window.dd.devConfig({
            onBeforeInvokeAPI: function (data) {
                socket.fireEvent({
                    method: "MiniAppLog.onApiCall" /* onApiCall */,
                    params: {
                        name: data.method,
                        reqId: data.invokeId,
                        params: {
                            content: data.params,
                        },
                    },
                    ext: {
                        origin: 'applog',
                    },
                });
            },
            onAfterInvokeAPI: function (data) {
                socket.fireEvent({
                    method: "MiniAppLog.onApiCallback" /* onApiCallback */,
                    params: {
                        name: data.method,
                        reqId: data.invokeId,
                        params: {
                            content: data.params,
                        },
                        res: {
                            success: !!data.isSuccess,
                            content: data.payload,
                        },
                    },
                    ext: {
                        origin: 'applog',
                    },
                });
            },
        });
    }
}
