"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
var lodash_1 = require("lodash");
var networkStore_1 = require("../../lib/networkStore");
var chromeResourceType_1 = require("../../utils/chromeResourceType");
var getResponseType_1 = require("../../utils/getResponseType");
function isInternalRequest(url) {
    var urlInst = new URL(url);
    return internalRequestHostCollection.indexOf(urlInst.host) !== -1;
}
var internalRequestHostCollection = [
    '1514539673795777.cn-hangzhou.fc.aliyuncs.com',
    'mdap.alipay.com',
];
exports.default = (function (socket) {
    function onRequestWillBeSent(requestItem) {
        if (requestItem.isInternalRequest || isInternalRequest(requestItem.url))
            return;
        socket.fireEvent({
            method: "Network.requestWillBeSent" /* requestWillBeSent */,
            params: {
                requestId: requestItem.id,
                loaderId: '',
                documentURL: requestItem.url,
                request: {
                    mixedContentType: 'none',
                    headers: requestItem.requestHeader,
                    method: requestItem.method,
                    url: requestItem.url,
                    initialPriority: 'Medium',
                    referrerPolicy: 'no-referrer-when-downgrade',
                    postData: lodash_1.isString(requestItem.postData) ? requestItem.postData : JSON.stringify(requestItem.postData),
                },
                initiator: {
                    type: 'script',
                },
                timestamp: (requestItem.startTime || performance.now()) / 1000,
                wallTime: performance.now() / 1000,
                hasUserGesture: false,
                type: requestItem.requestType,
            },
        });
    }
    function onResponseReceived(requestItem) {
        if (requestItem.isInternalRequest || isInternalRequest(requestItem.url))
            return;
        socket.fireEvent({
            method: "Network.responseReceived" /* responseReceived */,
            params: {
                requestId: requestItem.id,
                loaderId: '',
                timestamp: (requestItem.endTime || performance.now()) / 1000,
                type: requestItem.requestType,
                response: {
                    url: requestItem.url,
                    status: requestItem.status,
                    statusText: requestItem.statusText,
                    headers: requestItem.header,
                    mimeType: requestItem.responseType,
                    connectionReused: false,
                    connectionId: 0,
                    encodedDataLength: requestItem.encodedBodySize || 0,
                    securityState: 'neutral',
                    fromDiskCache: requestItem.costTime === 0,
                },
            },
        });
    }
    function onLoadingFinished(requestItem) {
        if (requestItem.isInternalRequest || isInternalRequest(requestItem.url))
            return;
        socket.fireEvent({
            method: "Network.loadingFinished" /* loadingFinished */,
            params: {
                requestId: requestItem.id,
                timestamp: (requestItem.endTime || performance.now()) / 1000,
                encodedDataLength: requestItem.encodedBodySize || 0,
            },
        });
    }
    function onLoadingFailed(requestItem) {
        socket.fireEvent({
            method: "Network.loadingFailed" /* loadingFailed */,
            params: {
                requestId: requestItem.id,
                timestamp: requestItem.endTime / 1000,
                type: requestItem.requestType,
                errorText: requestItem.statusText,
                canceled: false,
            },
        });
    }
    var requestHandler = {
        onRequestWillBeSent: onRequestWillBeSent,
        onResponseReceived: onResponseReceived,
        onLoadingFinished: onLoadingFinished,
        onLoadingFailed: onLoadingFailed,
    };
    /** mock xhr & fetch */
    networkStore_1.default.mockXHR(requestHandler);
    networkStore_1.default.mockFetch(requestHandler);
    /** collect html timing data */
    var url = networkStore_1.default.getURL(document.URL);
    var htmlLoadRequestItem = {
        id: networkStore_1.default.getUniqueID(),
        name: (url.pathname.split('/').pop() || '') + url.search,
        method: 'GET',
        url: document.URL,
        status: 200,
        statusText: 'OK',
        readyState: 4,
        header: {},
        responseType: 'text',
        requestType: 'Document',
        requestHeader: {
            Cookie: document.cookie,
            'Content-Type': 'text/html',
        },
        response: {
            base64Encoded: false,
            body: "<!DOCTYPE html>\n" + document.documentElement.outerHTML,
        },
        startTime: performance.timing.requestStart / 1000,
        endTime: performance.timing.responseEnd / 1000,
        costTime: (performance.timing.responseEnd - performance.timing.requestStart) / 1000,
        getData: {},
        mime: 'text/html',
    };
    networkStore_1.default.set(htmlLoadRequestItem.id, htmlLoadRequestItem);
    onRequestWillBeSent(htmlLoadRequestItem);
    onResponseReceived(htmlLoadRequestItem);
    onLoadingFinished(htmlLoadRequestItem);
    /** collect resource-loaded timing data */
    var resourcesLoaded = performance.getEntriesByType('resource');
    resourcesLoaded.forEach(function (resourcePerf) {
        var resourceUrl = networkStore_1.default.getURL(resourcePerf.name);
        var endTime = resourcePerf['responseEnd'] ? +resourcePerf['responseEnd'] : 0;
        var startTime = resourcePerf.startTime ? +resourcePerf.startTime : 0;
        var reqHeader = {
            Cookie: document.cookie,
            'Content-Type': chromeResourceType_1.ResourceType.mimeFromURL(resourcePerf.name),
        };
        var requestItem = {
            id: networkStore_1.default.getUniqueID(),
            name: (resourceUrl.pathname.split('/').pop() || '') + resourceUrl.search,
            method: 'GET',
            url: resourcePerf.name,
            status: 200,
            statusText: 'OK',
            readyState: 4,
            header: {},
            responseType: getResponseType_1.default(chromeResourceType_1.ResourceType.mimeFromURL(resourcePerf.name) || ''),
            requestType: chromeResourceType_1.ResourceType.fromMimeType(chromeResourceType_1.ResourceType.mimeFromURL(resourcePerf.name) || '')._title,
            requestHeader: reqHeader,
            response: {
                base64Encoded: false,
                body: '',
            },
            startTime: startTime,
            endTime: endTime,
            costTime: resourcePerf.duration,
            getData: {},
            encodedBodySize: resourcePerf['encodedBodySize'],
            mime: chromeResourceType_1.ResourceType.mimeFromURL(resourcePerf.name),
        };
        if (startTime === endTime) {
            requestItem.startTime = 0;
            requestItem.endTime = 0;
        }
        networkStore_1.default.set(requestItem.id, requestItem);
        onRequestWillBeSent(requestItem);
        onResponseReceived(requestItem);
        onLoadingFinished(requestItem);
    });
});
